import React, { useEffect, useState, useRef } from 'react';
// import './CommentBox.scss';

import MD5 from 'js-md5';
import { sha256 as SHA256 } from 'js-sha256';
import { store, authHeader, config } from '../../../_helpers';

import cx from 'classnames';
import {
    ProgressBarComponent,
    fileUploadConstants,
    s3InfoActions,
    s3Service,
    alertActions
} from '../../../components';
import { Spinner } from 'react-bootstrap';

const md5 = (x) => {
    const o = MD5.create();
    o.update(x);
    return o.base64();
};
const sha256 = (x) => {
    const o = SHA256.create();
    o.update(x);
    return o.hex();
};

function TemplateUploader({
    templateFile,
    templatesUploaderRef,
    onUploadComplete,
    onRemoveAttachment,
    index
}) {
    const { dispatch } = store;
    const [progressNow, setProgressNow] = useState();
    const [fileUploadTimeLeft, setFileUploadTimeLeft] = useState();
    const [fileUploadStatus, setFileUploadStatus] = useState();
    const templateInfoRef = useRef();

    useEffect(() => {
        if (!templatesUploaderRef) return;

        templatesUploaderRef(startUploading);
        startUploading();
    }, []);

    const onFileUploadStarted = () => {
        return Promise.resolve('success');
    };

    const startUploading = async () => {        
        var s3TempInfoPayload = {
            name: templateFile.file.name,
            location: 'email/customTemplate'            
        };
        var s3TempInfoResponse = await dispatch(
            s3InfoActions.getS3PublicInfo(s3TempInfoPayload)
        );
        templateInfoRef.current = {  id : s3TempInfoResponse.itemId, cloudFrontUrl : s3TempInfoResponse.cloudFrontUrl };

        window.onbeforeunload = () => true;
        await onFileUploadStarted();

        var s3TempInfo = {
            signerUrl: `${config.apiUrl}/s3/publicSignerUrl`,
            awsSignatureVersion: '4',
            //cloudfront: true,
            progressIntervalMS: 200,
            signHeaders: { ...authHeader() },
            computeContentMd5: true,
            // cryptoMd5Method: function (data) { return crypto.createHash('md5').digest('base64'); },
            // cryptoHexEncodedHash256 : function (data) { return crypto.createHash('sha256').update(data).digest('hex'); }
            cryptoMd5Method: (_) => md5(_),
            cryptoHexEncodedHash256: (_) => sha256(_),
            bucket: s3TempInfoResponse.bucket,
            awsRegion: s3TempInfoResponse.region,
            aws_key: s3TempInfoResponse.accessKey,
            sessionToken : s3TempInfoResponse.sessionToken
        };
        templateFile.file && uploadToS3(templateFile.file, s3TempInfo, s3TempInfoResponse.uploadPath);
    };

    const onFileUploadInit = (fileUploadPromise) => {
        dispatch(alertActions.loading(true));

        // this.setState({
        //     fileUploadPromise
        // });
    };

    const onProgressChange = (obj) => {
        console.log(obj);
        setProgressNow(obj.progressNow);
        setFileUploadStatus(fileUploadConstants.STARTED);
        setFileUploadTimeLeft(obj.stats.secondsLeft);
    };
    const onFileUploadCompleted = async (obj) => {
        dispatch(alertActions.loading(true));
        setFileUploadStatus(fileUploadConstants.FINALIZING);

        setProgressNow(obj.progressNow);
        setFileUploadStatus(fileUploadConstants.COMPLETED);
        dispatch(alertActions.loading(false));

        var uploadedNow = { ...templateInfoRef.current };
        uploadedNow.name = templateFile.file.name;
        onUploadComplete(uploadedNow);
    };
    const uploadToS3 = (file, s3TempInfo, uploadPath, callbacks) => {
        callbacks = {
            onFileUploadInit: onFileUploadInit,
            onProgressChange: onProgressChange,
            onFileUploadCompleted: onFileUploadCompleted
        };
        s3Service.uploadToS3(file, s3TempInfo, callbacks, uploadPath);
    };

    const renderTimeLeft = () => {
        var timeLeftInMinutes = (fileUploadTimeLeft / 60).toFixed(2);
        if (timeLeftInMinutes < 1) return <label className="ml-1">{fileUploadTimeLeft}s</label>;
        else return <label className="ml-1">{Math.ceil(timeLeftInMinutes)}min</label>;
    };

    return (
        <div
            style={{ background: 'var(--secondary-background-color)' }}
            className={cx({
                'attachment-details__container': true,
                'd-none': !templateFile?.file
            })}>
            <div className="attachment-output__list-item-container">
                <div
                    className="attachment-output__list-item-header"
                    style={{ margin: '8px 0px 4px 0px' }}>
                    <i className="fas fa-paperclip" />
                    <span
                        className="attachment-output__list-item-name text-truncate"
                        style={{ width: '93%' }}>
                        {templateFile?.name}
                    </span>
                    <span
                        className="attachment-close"
                        onClick={() => {
                            onRemoveAttachment(index);
                        }}>
                        <i className="fas fa-times" />
                    </span>
                </div>
                {templateFile?.file && (
                    <div className="attachment-output__list-item-footer">
                        <div className="attachment-output__list-item-footer__size">
                            <label className="">
                                {(templateFile.file.size / 1048576).toFixed(2)} mb
                            </label>
                        </div>
                        {fileUploadStatus === fileUploadConstants.STARTED && (
                            <>
                                <Spinner
                                    className="upload-spinner"
                                    animation="grow"
                                    variant="dark"
                                    size="sm"
                                />
                                <label className="mr-1 file-output__list-item-status__label">
                                    Uploading...
                                </label>
                            </>
                        )}
                        {fileUploadStatus === fileUploadConstants.STARTED && progressNow > 0 && (
                            <div className="attachment-output__list-item-footer__time">
                                {renderTimeLeft()}
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="attachment-output__list-item-middle-area">
                <ProgressBarComponent
                    className="progress-bar-attachment"
                    progressNow={progressNow}
                />
            </div>
        </div>
    );
}


export { TemplateUploader };
