//@ts-check
// import { Box, useColorModeValue, useMultiStyleConfig } from '@chakra-ui/react';
import { setHours, setMinutes } from 'date-fns';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
// import { useThemeStyles } from '../../../services/hooks/useThemeStyles';
import './CustomDateTimePicker.css';

const CustomDatePicker = ({
    value,
    onChange,
    minDate = null,
    isDateOnly = false,
    isDisabled = false,
    isInvalid = false,
    isClearable = false,
    dateFormat = 'MM/dd/yy',
    inputWidth = 'auto',
    inputFontSize = '16px'
}) => {
    // const {
    //     colors: {
    //         common: { border, border_active, border_hover }
    //     }
    // } = useThemeStyles();

    // const [startDate, setStartDate] = useState(setHours(setMinutes(new Date(), 0), 9));
    // const bgMenuColor = useColorModeValue('brandGray.50', 'brandGray.700');
    // const textBottomMenu = useColorModeValue('brandGray.700', 'brandGray.50');
    // const selectedBg = useColorModeValue(`blue.50`, 'blue.900');
    // const selectFontColor = useColorModeValue(`brandGray.800`, 'brandGray.50');
    // const inputBaseStyle = useMultiStyleConfig('Input', { variant: 'outline' });

    // const regexPattern = /yy/;
    //  const replacement = "yyyy";
    //  dateFormat = dateFormat.replace(regexPattern, replacement);

    const isValidDate = (dateObject) =>
        dateObject && new Date(dateObject).toString() !== 'Invalid Date';

    return (
        <DatePicker
            selected={value}
            onChange={(date) => {
                var sanetizedDate = isValidDate(date) ? new Date(date.toString()) : null;
                sanetizedDate =
                    isDateOnly && sanetizedDate
                        ? new Date(sanetizedDate.toDateString())
                        : sanetizedDate;
                onChange(sanetizedDate);
            }}
            placeholderText={`Please select a date`}
            showTimeSelect={!isDateOnly}
            dateFormat={isDateOnly ? dateFormat : `${dateFormat} h:mm aa`}
            minDate={minDate}
            isClearable={isClearable}
            //timeIntervals={5}
            // showPopperArrow={false}
            disabled={isDisabled}
            // ariaInvalid={isInvalid}
            className="_s_dateTimeField"
        />
    );
};

export default CustomDatePicker;
