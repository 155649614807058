import { authHeader, config, handleResponse, utilService } from '../../_helpers';
import { tenantService } from '../../components';
import Evaporate from 'evaporate';

export const s3Service = {
    getS3TempInfo,
    getS3MainInfo,
    cacheDocumentFromS3,
    deleteDocumentFromS3,
    cacheProofVersionFromS3,
    uploadToS3,
    uploadThumbnailToS3,
    getFileExtentions,
    getS3MainInfoAttachment,
    getS3PublicInfo,
    uploadFrame
};
function getS3TempInfo(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };

    return fetch(`${config.apiUrl}/s3/S3TempBucketInfo`, requestOptions).then(handleResponse);
}
function getS3MainInfo(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };

    return fetch(`${config.apiUrl}/s3/S3MainBucketInfo`, requestOptions).then(handleResponse);
}

function getS3MainInfoAttachment(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };

    return fetch(`${config.apiUrl}/s3/S3MainBucketAttachmentInfo`, requestOptions).then(handleResponse);
}

function getS3PublicInfo(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };

    return fetch(`${config.apiUrl}/s3/S3PublicBucketInfo`, requestOptions).then(handleResponse);
}

async function cacheDocumentFromS3(documentObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(documentObj)
    };

    return fetch(`${config.documentServiceUrl}/factory/CacheDocumentFromS3`, requestOptions).then(
        handleResponse
    );
}
async function deleteDocumentFromS3(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject)
    };

    return fetch(`${config.documentServiceUrl}/factory/DeleteObjectsFromS3`, requestOptions).then(
        handleResponse
    );
}
async function cacheProofVersionFromS3(proofVersion) {
    var ext = utilService.getFileExtention(proofVersion.name);
    var tenant = await tenantService.getCurrentTenantObject();
    var documentRequest = {
        DocumentId: `${proofVersion.id}.${ext}`,
        TenantName: tenantService.getCurrentTenant(),
        DocumentName: proofVersion.name,
        ObjectPath: proofVersion.fileURL,
        tenantInfo: tenant
    };
    await cacheDocumentFromS3(documentRequest);
}
function uploadToS3(file, S3Info, callbacks, uploadPath) {
    const { onFileUploadInit, onProgressChange, onFileUploadCompleted } = callbacks;  
    S3Info.logging = false;  
    let headers = {};
    if (S3Info.sessionToken) {
       headers = { 'x-amz-security-token': S3Info.sessionToken }
    }
    Evaporate.create(S3Info).then((evaporate) => {
        console.log('S3 transfer acceleration : ', evaporate.config.s3Acceleration);
        onFileUploadInit && onFileUploadInit(evaporate);
        evaporate
            .add({
                name: `${uploadPath}`,
                file: file,
                progress: (percent, stats) => {
                    console.log('Progress', percent, stats, percent * 100);
                    var progressObj = {
                        progressNow: Math.ceil(percent * 100),
                        stats: stats,
                        fileName: file.name
                    };
                    onProgressChange && onProgressChange(progressObj);
                },
                progressIntervalMS: 200,
                complete: () => {},
                contentType: file.type,
                xAmzHeadersCommon: headers,
                xAmzHeadersAtInitiate: headers
            })
            .then((awsObjectKey) => {
                console.log('file location: ', awsObjectKey);
                var progressObj = {
                    progressNow: 100,
                    completed: true,
                    awsObjectKey
                };
                console.timeEnd('uploadTimer : ' + uploadPath);
                onFileUploadCompleted && onFileUploadCompleted(progressObj);
            })
            .catch((err) => {
                console.log('error', err);
            });
    });
}
async function uploadThumbnailToS3(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject)
    };

    return fetch(`${config.documentServiceUrl}/page/UploadThumbnailToS3`, requestOptions).then(
        handleResponse
    );
}

async function getFileExtentions(request) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/s3/getFileExtentions`, requestOptions).then(handleResponse);
}

async function uploadFrame (frame, s3Config, uploadPath) {
        
    try {
        
        // switchDocumentEditorDirty(true);
        let callbacks = {
            onProgressChange: (e) => {
            },
            onFileUploadCompleted: () => {
                console.log('FRAME UPLOAD Completed');
                // switchDocumentEditorDirty(false);
            }
        };
        s3Service.uploadToS3(frame, s3Config, callbacks, uploadPath);
    } catch (error) {
        console.log(error);
    }
}
