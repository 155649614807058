import React, { useEffect, useState, useRef } from 'react';
// import './CommentBox.scss';

import MD5 from 'js-md5';
import { sha256 as SHA256 } from 'js-sha256';
import { store, authHeader, config } from '../../../../../_helpers';

import cx from 'classnames';
import {
    ProgressBarComponent,
    fileUploadConstants,
    s3InfoActions,
    s3Service,
    alertActions
} from '../../../../../components';
import { Spinner } from 'react-bootstrap';
import { useScreenSizes } from '../../../../responsive/useScreenSizes';
import CloseIcon from '../../../../_shared/Icon/CloseIcon';
import { conditionalClassName } from '../../../../responsive/responsiveUtils';
import { secondarySmallTextStyle } from '../../../../_shared/Styles/TextStyles';
import DesktopBreakpoint from '../../../../responsive/DesktopBreakpoint';
import PhoneBreakpoint from '../../../../responsive/PhoneBreakpoint';

const md5 = (x) => {
    const o = MD5.create();
    o.update(x);
    return o.base64();
};
const sha256 = (x) => {
    const o = SHA256.create();
    o.update(x);
    return o.hex();
};

function AttachmentUploader({attachments, updateUploadHandlers, onUploadComplete, onRemoveAttachment, index, isMobile }) {
    const { dispatch } = store;
    const [progressNow, setProgressNow] = useState();
    const [fileUploadTimeLeft, setFileUploadTimeLeft] = useState();
    const [fileUploadStatus, setFileUploadStatus] = useState();
    const [attachmentId, setAttachmentId] = useState('');
    const attachmentIdRef = useRef();
    
    useEffect(() => {
        attachmentIdRef.current = attachmentId;
    }, [attachmentId]);

    useEffect(() => {
        updateUploadHandlers(startUploading);
    }, []);

    const onFileUploadStarted = () => {
        return Promise.resolve('success');
    };

    const startUploading = async () => {
        var s3TempInfoPayload = {
            name: attachments.file.name
        };
        var s3TempInfoResponse = await dispatch(
            s3InfoActions.getS3MainAttachmentInfo(s3TempInfoPayload)
        );
        setAttachmentId(s3TempInfoResponse.attachmentId);        
        window.onbeforeunload = () => true;
        await onFileUploadStarted();

        var s3TempInfo = {
            signerUrl: `${config.apiUrl}/s3`,
            awsSignatureVersion: '4',
            progressIntervalMS: 200,
            signHeaders: { ...authHeader() },
            computeContentMd5: true,
            cryptoMd5Method: (_) => md5(_),
            cryptoHexEncodedHash256: (_) => sha256(_),
            bucket: s3TempInfoResponse.bucket,
            awsRegion: s3TempInfoResponse.region,
            aws_key: s3TempInfoResponse.accessKey,
            s3Acceleration : s3TempInfoResponse.transferAcceleration,
            sessionToken : s3TempInfoResponse.sessionToken
        }; 
        attachments.file && uploadToS3(attachments.file, s3TempInfo, s3TempInfoResponse.uploadPath);
    };

    const onFileUploadInit = (fileUploadPromise) => {
        dispatch(alertActions.loading(true));

        // this.setState({
        //     fileUploadPromise
        // });
    };

    const onProgressChange = (obj) => {
        console.log(obj);
        setProgressNow(obj.progressNow);
        setFileUploadStatus(fileUploadConstants.STARTED);
        setFileUploadTimeLeft(obj.stats.secondsLeft);

    };
    const onFileUploadCompleted = async (obj) => {
        dispatch(alertActions.loading(true));
        setFileUploadStatus(fileUploadConstants.FINALIZING);
    
        setProgressNow(obj.progressNow);
        setFileUploadStatus(fileUploadConstants.COMPLETED);
        dispatch(alertActions.loading(false));

        var uploadedNow = {
            id: attachmentIdRef.current,
            name: attachments.file.name,
            fileType: attachments.file.type
        };

        onUploadComplete(uploadedNow);
    };
    const uploadToS3 = (file, s3TempInfo, uploadPath, callbacks) => {        
        callbacks = {
            onFileUploadInit: onFileUploadInit,
            onProgressChange: onProgressChange,
            onFileUploadCompleted: onFileUploadCompleted
        };
        s3Service.uploadToS3(file, s3TempInfo, callbacks, uploadPath);
    };

    const renderTimeLeft = () => {
        var timeLeftInMinutes = (fileUploadTimeLeft / 60).toFixed(2);
        if (timeLeftInMinutes < 1) return <label className="ml-1">{fileUploadTimeLeft}s</label>;
        else return <label className="ml-1">{Math.ceil(timeLeftInMinutes)}min</label>;
    };
    
    

    return (
        <div
            style={isMobile? {margin: '4px 0px 0px 0px', background: 'var(--secondary-background-color)'} : {}}
            className={cx({
                'attachment-details__container': true,
                'd-none': !attachments?.file
            })}>
            <div 
                style={isMobile? {margin: '10px'} : {}}
                className="attachment-output__list-item-container">
                <div
                    style={isMobile? {margin: '0px'} : {}} 
                    className="attachment-output__list-item-header">
                    <i className="fas fa-paperclip" />
                    <span style={isMobile? secondarySmallTextStyle : {}} className="attachment-output__list-item-name text-truncate">
                        {attachments?.fileName}
                    </span>
                    <span className='attachment-close' onClick={()=> {onRemoveAttachment(index)}}>
                        {/* <i className="fas fa-times" /> */}
                        <CloseIcon/>
                    </span>
                </div>
                {attachments?.file && (
                    <div style={isMobile? secondarySmallTextStyle : {}} className="attachment-output__list-item-footer">
                        <div className="attachment-output__list-item-footer__size">
                            <DesktopBreakpoint>
                                <label className="">
                                    {(attachments.file.size / 1048576).toFixed(2)} mb
                                </label>
                            </DesktopBreakpoint>
                            <PhoneBreakpoint>
                                <span style={{marginLeft: '20px'}} className="">
                                    {(attachments.file.size / 1048576).toFixed(2)} mb
                                </span>
                            </PhoneBreakpoint>

                        </div>
                        {fileUploadStatus === fileUploadConstants.STARTED && (
                            <>
                                <Spinner className='upload-spinner' animation="grow" variant="dark" size="sm" />
                                <label className="mr-1 file-output__list-item-status__label">
                                    Uploading...
                                </label>
                            </>
                        )}
                        {fileUploadStatus === fileUploadConstants.STARTED && progressNow > 0 && (
                            <div className="attachment-output__list-item-footer__time">                                
                                {renderTimeLeft()}
                            </div>
                        )}
                    </div>
                )}
            </div>

            {
                fileUploadStatus &&
                <div className="attachment-output__list-item-middle-area">
                    <ProgressBarComponent
                        className={conditionalClassName('progress-bar-attachment', isMobile, 'mobile')}
                        progressNow={progressNow}
                    />
                </div>
            }

        </div>
    );
}

function AttachmentsUploader({ attachments, onUploadComplete, updateUploadHandlers, onRemoveAttachment }) {    
    const { isMobile } = useScreenSizes();
    return (
        <>
            {attachments && (
                <div className='attachments-list' style={isMobile? {padding: '8px 0px 0px 0px'} : {}}>
                    {attachments.map((attachments, index) => {
                        return (
                            <AttachmentUploader
                                attachments={attachments}
                                key={attachments.guid}
                                onUploadComplete={onUploadComplete}
                                updateUploadHandlers={updateUploadHandlers}
                                onRemoveAttachment= {onRemoveAttachment}
                                index={index}
                                isMobile={isMobile}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
}

export { AttachmentsUploader };
