//@ts-check
import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { TemplateUploader } from './TemplateUploader';
import CustomEmailTemplateFilePreviewer from './CustomEmailTemplateFilePreviewer';
import { utilService } from '../../../_helpers';


const ALLOWED_DIMENSIONS = {
    width: 600,
    height: 125
};

const EmailHeaderFooterBuilderComponent = ({
    templatesUploaderRef,
    setEmailSettings,
    emailSettings,
    settingPropName
}) => {
    const [validFile, setValidFile] = useState();
    const fileInputRef = useRef();
    const [errorMessage, setErrorMessage] = useState();
    const validateFileType = (file) => {
        //Initiate the JavaScript Image object.
        var isTypeSupported = isValidFileType(file);
        if(isTypeSupported === false) {
            console.log('File size is not supported');
            setErrorMessage('File type is not supported, Supported file types are : .jpg, .jpeg, .png');
            return;
        }

        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = window.URL.createObjectURL(file);

        //Validate the File Height and Width.
        image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height !== ALLOWED_DIMENSIONS.height || width !== ALLOWED_DIMENSIONS.width) {
                console.log('Image dimension must be : 600 x 125');
                setErrorMessage('Cannot upload, Image dimension size must be : 600 x 125');
                return;
            }

            let validFile = {
                name: file.name,
                // id: uuid(),
                file: file,
                isUploaded: false
            };

            setValidFile(validFile);
        };
    };

    const isValidFileType = (pickedFile)=> {        
        var supportedTypes = ['jpg', 'jpeg', 'png'];
        var type = utilService.getFileExtention(pickedFile.name).toLowerCase();        
        return supportedTypes.some((x) => x === type);
    }

    const onFilesAttached = async (pickedFiles) => {
        // setErrorMessage('');
        // setFileErrorMessage('');

        pickedFiles.forEach(async (pickedFile) => {
            let isValidType = validateFileType(pickedFile);

            if (isValidType === false) {
                setFileErrorMessage('File type not supported');
                return;
            }

            //_fileOriginalName[validFIleCount] = pickedFile.name;
        });
    };

    const onAttachmentChanged = async (e) => {
        let pickedFiles = [...e.target.files];
        onFilesAttached(pickedFiles);
    };
    const onBrowseClick = (e) => {
        setErrorMessage(undefined);
        e.preventDefault();
        fileInputRef.current.click();
    };

    const onUploadComplete = (uploaded) => {
        updateEmailSetting(uploaded.cloudFrontUrl);
    };
    const updateEmailSetting = (url) => {       
        setEmailSettings((prev) => {
            var setting = { ...prev };
            setting[settingPropName] = url;
            return setting;
        });
    };
    const updateUploadHandlers = () => {};
    const onRemoveAttachment = () => {
        setValidFile();
        updateEmailSetting('');
    };

    const hasValue = () => {
        if (!emailSettings) { return };
        let value = emailSettings[settingPropName];
        let hasValue = value === undefined || value === null || value === '';        
        return !hasValue;
    };

    return (
        <div className="custom-template-header-builder">            
            {validFile ? (
                <div className="custom-template-file-preview">
                    <div>
                        <TemplateUploader
                            templateFile={validFile}
                            onUploadComplete={onUploadComplete}
                            templatesUploaderRef={templatesUploaderRef}
                            onRemoveAttachment={onRemoveAttachment}
                            index={0}
                        />

                        <CustomEmailTemplateFilePreviewer showActions={false} src={window.URL.createObjectURL(validFile.file)} />
                    </div>
                </div>
            ) : hasValue() === true ? (
                <CustomEmailTemplateFilePreviewer src={emailSettings[settingPropName]} showActions={true} onRemove={()=> {updateEmailSetting('')}} />
            ) : (
                <div className="custom-template-file-picker">
                    <Button variant="secondary" onClick={onBrowseClick}>
                        Upload
                    </Button>
                    {errorMessage?  (
                        <span style={{ marginLeft: '12px', color: 'red' }}>{errorMessage}</span>
                    ) : <span style={{ marginLeft: '12px' }}>Image size : 600 x 125, Supported types : .jpg, .jpeg, .png </span> }
                    
                    <div className="file-upload__modal" role="section">
                        <form className="">
                            <input
                                type="file"
                                ref={fileInputRef}
                                id="file-dropzone"
                                accept=".jpg, .jpeg, .png"
                                className="file__input js-file__input mr-2 mb-2"
                                onChange={onAttachmentChanged}
                            />
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};


export default EmailHeaderFooterBuilderComponent;